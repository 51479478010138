import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import 'bootstrap/js/src/util';
import SmoothScroll from 'smooth-scroll';

import WebFont from "webfontloader";
WebFont.load({google: {families: ["Roboto:300,400,500"]}});

import './assets/scss/main.scss'

$(document).ready(() => {
  let scroll = new SmoothScroll('#navbarNavAltMarkup a[href*="#"]')
})
